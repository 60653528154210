.block {
    position: absolute;
    height: 100%;
    box-sizing: border-box;
    padding: 2px;
    border-radius: 5px;
    min-width: 5px;
    z-index: 20;
    background-color: rgb(162, 145, 251);
}
