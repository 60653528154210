@import "../../breakpoints.scss";

.content {
    padding: 8px;
    margin: 0 20px;
    position: relative;
}

@media (min-width: $breakpoint-l) {
    .content {
        margin: 20px 40px;
    }
}
