.info {
    height: 1em;
    display: flex;
    justify-content: flex-start;
    color: rgba(0, 0, 0, 0.6);

    div:not(:last-child) {
        margin-right: 10px;
    }

    div:not(:first-child) {
        margin-left: 10px;
    }
}
