.wrapper {
    margin-bottom: 30px;

    .timeline {
        position: relative;
        height: 20px;
        overflow: hidden;
        margin-top: 3px !important;
    }

    .name {
        font-weight: bold;
    }
}

.tickContainer {
    position: relative;

    .tick {
        top: 0px;
        position: absolute;

        .tickLine {
            position: absolute;
            z-index: 10;
        }

        .tickLabel {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 100px;
            top: 25px;
            position: absolute;
            color: rgba(0, 0, 0, 0.6);
            font-size: 0.8rem;
        }

        &:not(:nth-child(2)) {
            .tickLine {
                height: 25px;
                border-left: 1px solid rgb(212, 212, 213);
            }
        }
    }
}
