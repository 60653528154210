.column {
    padding-bottom: 20px !important;
}

.buttonContainer {
    display: flex;
    justify-content: flex-start;
}

.description {
    height: 60px;
}
