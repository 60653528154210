@import "../../breakpoints.scss";

$appBarHeight: 32pt;

.appBar {
    height: $appBarHeight;
}

@media (max-width: $breakpoint-m) {
    .veryweak {
        display: none !important;
    }
}

@media (max-width: $breakpoint-s) {
    .weak {
        display: none !important;
    }
}

.avatar {
    margin-left: 10px;
}
