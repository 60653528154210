.fileWrapper {
    width: 100%;
    margin: auto;
    position: relative;
    overflow: hidden;
    background-color: rgba(199, 199, 199, 0.2);
    color: rgb(199, 199, 199);
    border: 4px dashed rgb(199, 199, 199);
    box-sizing: border-box;
    border-radius: 5px;

    text-align: center;
    padding-top: 70px;
    margin-bottom: 20px;
    padding-bottom: 70px;

    input {
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        padding: 0;
        opacity: 0;
        height: 100%;
        width: inherit;
        cursor: pointer;
    }
}

.fileWrapperBigText {
    font-size: 15pt;
    margin: auto;
}

.fileWrapperSmallText {
    font-size: 10pt;
    margin: auto;
}

.icon {
    color: rgb(199, 199, 199);
    width: 100px;
    height: 100px;
}
